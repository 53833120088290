import { dbg } from '../utils/dbg';
import getJson from '../utils/helpers/getJson';
import { setElementAttributes } from '#Modules/utils/helpers/setElementAttributes';
import { getConfig } from '../config';
import { setCookie, getCookie, removeCookie } from '../utils/cookies';
import { getCookieStorageDomainName } from '../utils/helpers/getDomainName';
import { setPaws } from './externalPaws';
import DOT from './DOT';
import { CONSENT } from '@sklik/cmp2-common';

let syncIframe = null;
let syncIframeLoaded = null;

const getCookieFromResponse = (messageData) => {
  const cmpData = getJson(messageData);

  if (cmpData && cmpData.cmp === 'szn' && cmpData.method === 'get' && cmpData.cookie) {
    return cmpData.cookie;
  }

  return null;
};

const createSyncIframe = () =>
  new Promise((resolve) => {
    if (syncIframe && syncIframeLoaded) {
      return resolve(syncIframe);
    }

    if (!syncIframe) {
      syncIframe = document.createElement('iframe');
    }

    if (!syncIframeLoaded) {
      syncIframe.addEventListener('load', () => {
        syncIframeLoaded = true;
        resolve(syncIframe);
      });
    }

    const { protocol, staticServer } = getConfig();

    setElementAttributes(syncIframe, {
      src: `${protocol}${staticServer}/static/html/cmp.html`,
      frameborder: 0,
      style: 'display: none;',
    });

    document.body.appendChild(syncIframe);
  });

const getResponseFromIframe = (iframe) =>
  new Promise((resolve, reject) => {
    const parseMessageCallback = (event) => {
      return event.data && resolve(event.data);
    };

    window.addEventListener('message', parseMessageCallback);
    setTimeout(reject, 5000);

    const { protocol, staticServer } = getConfig();
    const CMP_SSP_IFRAME_URL = protocol + staticServer;
    const message = JSON.stringify({
      method: 'getCookie',
      type: 'ext',
    });

    iframe.contentWindow?.postMessage(message, CMP_SSP_IFRAME_URL);
  });

const getCookieFromGlobalStorage = async () => {
  const iframe = await createSyncIframe();
  const response = await getResponseFromIframe(iframe);
  const cookieValue = getCookieFromResponse(response);
  if (!cookieValue) {
    throw new Error('No consent data found in global storage');
  }
  return cookieValue;
};

export const getConsentV2FromGlobalStorage = async () => {
  try {
    const cookie = await getCookieFromGlobalStorage();
    return cookie;
  } catch (e) {
    return null;
  }
};

export const copyGlobalConsent = ({ consentString }) => {
  const browserCookie = {
    name: CONSENT.CONSENT_COOKIE_NAME,
    value: getCookie(CONSENT.CONSENT_COOKIE_NAME),
  };
  const domainName = getCookieStorageDomainName(window.location.hostname);

  if (!browserCookie.value) {
    dbg('info', '### Cookie not found in local.');
    setCookie({
      name: browserCookie.name,
      value: consentString,
      domain: domainName,
      expires: [365, 'days'],
    });
    DOT.config();
    DOT.cookieCopied();
    if (window.Cypress) {
      // Let Cypress know it can start looking for a cookie
      window.document.body.dataset.cyCookieSynced = true;
    }
  } else {
    dbg('info', '### Cookie found in local - resave for 2nd level domain');
    removeCookie({ name: browserCookie.name });
    setCookie({
      name: browserCookie.name,
      value: browserCookie.value,
      domain: domainName,
      expires: [365, 'days'],
    });
  }
};

export const copyCookieFromGlobalStorage = () => {
  getConsentV2FromGlobalStorage().then((consent) => {
    const globalConsent = consent
      ? {
          consentString: consent,
        }
      : null;

    if (globalConsent) {
      copyGlobalConsent(globalConsent);
    }

    setPaws(Boolean(globalConsent));
  });
};
