import { getConfig } from '../config';
import { INT_PAW_LINK } from '../../constants';
import { getExternalPawsUrl } from '../cmp/externalPaws';
import { getImageBaseUrl } from '../utils/urls';

const getPawIcon = (type = '') => {
  let iconUrl = null;
  let rotationAngle = 0;

  switch (type.toUpperCase()) {
    case 'OGAR':
      iconUrl = `${getImageBaseUrl()}/paw-2.svg`;
      rotationAngle = 90;
      break;
    case 'ADVERT':
      iconUrl = `${getImageBaseUrl()}/paw.svg`;
      rotationAngle = 90;
      break;
    case 'APPNEXUS':
    case 'XANDR':
      iconUrl = `${getImageBaseUrl()}/paw-2.svg`;
      rotationAngle = 180;
      break;
    case 'CRITEO':
      iconUrl = `${getImageBaseUrl()}/paw.svg`;
      rotationAngle = 180;
      break;
    case 'SKLIK':
      iconUrl = `${getImageBaseUrl()}/paw-2.svg`;
      rotationAngle = 0;
      break;
    case 'ONEGAR':
      iconUrl = `${getImageBaseUrl()}/paw.svg`;
      rotationAngle = -90;
      break;
    case 'PUBMATIC':
    case 'PUBMATIC2':
      iconUrl = `${getImageBaseUrl()}/paw-2.svg`;
      rotationAngle = -90;
      break;
    default:
      iconUrl = `${getImageBaseUrl()}/paw.svg`;
      rotationAngle = 0;
  }

  const style = `
		width: 13px;
		height: 13px;
		position: absolute; left: 50%; top: 50%;
		transform-origin: center center;
		transform: translate(-50%, -50%) rotate(${rotationAngle}deg);
	`;

  return `<img src="${iconUrl}" data-cy-paw="${type.toLowerCase()}" style="${style}">`;
};

/**
 * Generates HTML code of the paw icon (in the bottom-right corner of an ad container)
 * @param {String} type DSP type (SKLIK, APNEXUS,..)
 * @param {Boolean} visible - visibility of "paw"
 * @returns {String} paw HTML
 */
const createPawIconTemplate = (type, pawUrl = INT_PAW_LINK, visible) => {
  const url = getImageBaseUrl();
  return `
		<div style="position:absolute; bottom:0; right:0; height:15px;${!visible ? ' visibility: hidden' : ''}" class="packa">
			<a target="_blank" class="packa-anchor" href="${pawUrl}"${
        pawUrl.startsWith('https://ssp.seznam.cz/ad-info') ? ' data-dot-data=\'{"action": "showAdInfoPage"}\'' : ''
      }>
					<div class="packa-icon" style="width: 15px; height: 15px; position: relative;">
						${getPawIcon(type)}
					</div>
					<img src="${url}/advert.svg" style="display:none; height:15px" data-cy-pawinfo="seznam-reklama">
			</a>
		</div>
	`;
};

/**
 * Returns paw code for all DSP types except Sklik, OGAR & ONEGAR
 * @param {String} type DSP type (SKLIK, APNEXUS,..)
 * @param {Boolean} visible - visibility of "paw"
 * @returns {String} paw HTML
 */
const getPawIconTemplate = (type, adInfoUrl, visible) => {
  const cmpData = getConfig();
  let pawUrl = adInfoUrl;
  if (!pawUrl) {
    if (cmpData && cmpData.internalPartner === false) {
      pawUrl = getExternalPawsUrl();
    } else {
      pawUrl = INT_PAW_LINK;
    }
  }

  return createPawIconTemplate(type, pawUrl, visible);
};

export { createPawIconTemplate, getPawIconTemplate };
