import { getDot } from '../impress/dotLoader';
import { dbg } from './dbg';
import getJson from './helpers/getJson';

const REPORTS_ACTION_ID = 'reports_collection';

/**
 * Only one Observer can be registered for each frame
 */
let registered = false;
export const _setIsRegistered = (r = true) => (registered = r);
export const _getIsRegistered = () => registered;

/**
 * Send DOT hits with report/s data
 */
export const sendReports = (reports) => {
  const DOT = getDot();
  if (!DOT.hit) {
    dbg('warn', `Browser reports / interventions (${reports.length}) couldn't be sent. DOT.hit() missing.`);
    return;
  }
  dbg('info', `Sending browser reports / interventions (${reports.length})`);
  for (const report of reports) {
    /*
			Report={type: 'intervention', url: 'https://ssp.im.cz/static/html/www.seznam.cz/reklama.html?url=https%3A%2F%2Fwww.seznam.cz%2F', body: InterventionReportBody}
			InterventionReportBody={id: 'HeavyAdIntervention', message: 'Ad was removed because its peak CPU usage exceeded…, sourceFile: null, lineNumber: null, columnNumber: null}
		*/
    dbg('Intervention reports item', report);
    DOT.hit('heavyAdIntervention');
  }
};

/**
 * Trigger reports sending (direct or send to parent)
 */
export const processReports = (reports, topLevel) => {
  if (topLevel) {
    // we are in toplevel -> we have DOT
    sendReports(reports);
  } else {
    // we are nested -> send event to parent to send hits
    window.parent.postMessage(
      JSON.stringify({
        action: REPORTS_ACTION_ID,
        reports,
      }),
      '*'
    );
  }
};

/**
 * If supported by browser, reporting observer is registered and collects (intervention) reports from itself or from child iframe.
 * Reports are send after collection or before page is hidden
 */
const initReporting = (topLevel = false) => {
  if (!_getIsRegistered() && 'ReportingObserver' in window) {
    _setIsRegistered(true);

    const observer = new window.ReportingObserver(
      (reports) => {
        processReports(reports, topLevel);
      },
      { types: ['intervention'], buffered: true }
    );
    observer.observe();

    dbg('ReportingObserver registered');

    window.addEventListener('pagehide', () => {
      const reports = observer.takeRecords();
      processReports(reports, topLevel);
    });

    if (topLevel) {
      window.addEventListener('message', (event) => {
        const message = getJson(event.data);
        if (message && message.action === REPORTS_ACTION_ID && message.reports) {
          sendReports(message.reports);
        }
      });
    }
  }
};

export default initReporting;
