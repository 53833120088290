import { DSP_LIST } from '../../constants';
import { getPawIconTemplate } from './createPawIconTemplate';

const isValidDSP = (dsp) => DSP_LIST.includes(dsp);
export const isBranding = (width) => width > 1900;

/**
 * Returns pawHTML for all DSP types except Sklik, OGAR & ONEGAR
 * @param {Object} ad - reklama z sssp
 * @param {Boolean} visible - viditelnost "packy"
 * @returns {String | null} paw HTML
 */
export const getPawHTML = (ad, visible = false) => {
  if (!ad || !ad.dsp || !isValidDSP(ad.dsp)) {
    return null;
  }

  return getPawIconTemplate(ad.dsp, ad.adInfoUrl, visible) || null;
};

/**
 * Handle collapse/expand mouse handler
 */
const _pawMouseHandler = (event) => {
  event.stopPropagation();
  const togglePawFragment = (which) => {
    const fragment = event.target.closest('.packa-anchor')?.[`${which}ElementChild`];
    fragment.style.display = getComputedStyle(fragment).display === 'block' ? 'none' : 'block';
  };
  togglePawFragment('first');
  togglePawFragment('last');
};

/**
 * Fix to corner (reposition if outside viewport -> branding implementation on external partners)
 */
const _pawReposition = (element) => {
  element.style.position = 'fixed';

  const box = element.getBoundingClientRect();

  const rightOverflow = box.right - document.documentElement.clientWidth;
  const bottomOverflow = box.bottom - document.documentElement.clientHeight;

  let translate = '';

  if (rightOverflow > 0) {
    translate += ` translateX(-${rightOverflow}px)`;
  }
  if (bottomOverflow > 0) {
    translate += ` translateY(-${bottomOverflow}px)`;
  }

  if (!translate) {
    return;
  }
  element.style.transform = translate.trim();
};

/**
 * Add listeners to (not already handled) paw icons (+ reposition if needed)
 */
export const revivePaw = (ad, context) => {
  window.setTimeout(() => {
    const mark = 'ssp-handled';
    const paw = context.querySelector(`.packa:not(.${mark})`);

    // no paw element (or mocked in JEST)
    if (!(paw instanceof HTMLElement)) {
      return;
    }

    const pawAnchor = paw.querySelector('.packa-anchor');

    pawAnchor.addEventListener('mouseover', _pawMouseHandler);
    pawAnchor.addEventListener('mouseout', _pawMouseHandler);
    paw.classList.add(mark);
    if (isBranding(ad.width)) {
      _pawReposition(paw);
    }
  });
};
