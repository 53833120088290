const runInfoCallback = (ad, data) => {
  if (!data.options || !data.options.infoCallback) {
    return;
  }

  const clearedAd = { ...ad };
  delete clearedAd.data;
  delete clearedAd.tracking;
  data.options.infoCallback(clearedAd, data);
};

export default runInfoCallback;
