/**
 * Run callback inside try catch
 * @param {function} callback - function to be executed
 * @return {boolean} True if successfull, false if error
 */
export const safelyExecuteCallback = (callback) => {
  try {
    callback();
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
};
