import { UADATA } from '@iva/utils';
/**
 * User Agent Data headers that are accepted by advert server
 */
const uaHeadersMap = {
    fullVersionList: 'X-Sec-CH-UA-Full-Version-List',
    model: 'X-Sec-CH-UA-Model',
    mobile: 'X-Sec-CH-UA-Mobile',
    platform: 'X-Sec-CH-UA-Platform',
    platformVersion: 'X-Sec-CH-UA-Platform-Version',
};
/**
 * Retrieves available low & high entropy values from the User Agent Data API
 */
export const getFormattedUaDataHeaders = async () => {
    const uaData = await UADATA.getHighEntropyValues(Object.keys(uaHeadersMap));
    if (!uaData) {
        return null;
    }
    const formattedUaDataHeaders = {};
    if (uaData.fullVersionList) {
        const fullVersionListAsString = uaData.fullVersionList
            .map((item) => `"${item.brand}";v="${item.version}"`)
            .join(', ');
        formattedUaDataHeaders[uaHeadersMap.fullVersionList] = fullVersionListAsString;
    }
    if (uaData.model) {
        formattedUaDataHeaders[uaHeadersMap.model] = uaData.model;
    }
    if (typeof uaData.mobile === 'boolean') {
        formattedUaDataHeaders[uaHeadersMap.mobile] = uaData.mobile ? '?1' : '?0';
    }
    if (uaData.platform) {
        formattedUaDataHeaders[uaHeadersMap.platform] = `"${uaData.platform}"`;
    }
    if (uaData.platformVersion) {
        formattedUaDataHeaders[uaHeadersMap.platformVersion] = uaData.platformVersion;
    }
    return formattedUaDataHeaders;
};
