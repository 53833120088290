import { SSP_URL } from '../constants';
import { sendPawVisibilityEvent } from '../modules/utils/events';
import { getScaleModifier } from '../modules/utils/helpers/scaling';
import { getIS } from '../modules/utils/identityStorage';

var sf = {};
sf.host = {};

// interni konf
var _conf = {};
_conf.server = `${SSP_URL.PROTOCOL}${SSP_URL.IFRAME_HOSTNAME}`;
_conf.resizeUpdateInterval = 1000;
_conf.scrollUpdateInterval = 100;
_conf.registeredServers = [_conf.server];

var conf = {};
// podporovane prostredky
// musi se predat z hosta pri vytvoreni iframu
conf.support = {
  'exp-ovr': true,
  'exp-push': true,
  'read-cookie': false,
  'write-cookie': false,
};

var timeoutID = null;
// posluchac z iframe
sf._listen = function (e) {
  if (_conf.registeredServers.indexOf(e.origin) !== -1) {
    //if (e.origin == _conf.server ) {
    try {
      var d = JSON.parse(e.data);
      var id = d.sfName;
      var sfInstance = sf.SF.frames[id];
      switch (d.method) {
        case 'register':
          sfInstance._register(d);
          break;
        case 'expand':
          sfInstance._expand(d);
          break;
        case 'collapse':
          sfInstance._collapse(d);
          break;
        case 'setResizeProperties':
          sfInstance._setResizeProperties(d);
          break;
        case 'cookie':
          sfInstance._cookie(d);
          break;
      }
    } catch (E) {
      // Nothing happens
    }
  }
};

var throttle = function throttle(fn, timeout) {
  var lastFc = null;
  var timeStamp = null;
  return function () {
    function fnCall() {
      fn.apply(this, arguments);
      timeStamp = Date.now();
    }

    if (!timeStamp) {
      fnCall();
    } else {
      clearTimeout(lastFc);
      lastFc = setTimeout(
        function () {
          if (Date.now() - timeStamp >= timeout) {
            fnCall();
          }
        },
        timeout - (Date.now() - timeStamp)
      );
    }
  };
};

// posluchac na scroll udalost
sf._resize = function () {
  if (timeoutID === null) {
    timeoutID = setTimeout(function () {
      timeoutID = null;
      for (var instanceSF in sf.SF.frames) {
        var i = sf.SF.frames[instanceSF];
        // eslint-disable-next-line camelcase
        var d = { method: 'geom', geom_info: i.getGeom() };
        i.sendData(d);
      }
    }, _conf.resizeUpdateInterval);
  }
};

sf._getInstance = function () {
  for (var instanceSF in sf.SF.frames) {
    var i = sf.SF.frames[instanceSF];
    // eslint-disable-next-line camelcase
    var d = { method: 'geom', geom_info: i.getGeom() };
    i.sendData(d);
  }
};

window.addEventListener('message', sf._listen);
window.addEventListener(
  'scroll',
  throttle(function () {
    sf._getInstance();
  }, _conf.scrollUpdateInterval)
);
window.addEventListener('resize', sf._resize);

// konstruktor pro jednu danou pozici
sf.SF = function (iframe, ad, data, server) {
  // nastaveni serveru - kvuli test a dev prostredi
  this.server = server ? server : _conf.server;
  if (server) {
    _conf.registeredServers.push(server);
  }

  this.name = 'sf' + sf.SF.count++;

  iframe.id = this.name;
  sf.SF.frames[iframe.id] = this; // jako klic pouzijeme window iframu, v odpovedi z iframu je pak e.source

  this.iframe = iframe;
  var getElm = function (elm, className) {
    var count = 0;
    while (count < 10 && elm && elm.nodeName !== 'BODY') {
      count++;
      if (elm.classList.contains(className)) {
        return elm;
      } else {
        elm = elm.parentNode;
      }
    }
  };

  this.cont = getElm(iframe, 'adFull') || { dataset: {}, offsetWidth: 0, offsetHeight: 0 };

  // ad has smaller height, than native default frame height (111px vs 150px)
  if (ad.height < this.cont?.offsetHeight) {
    this.cont.style.height = ad.height + 'px';
  }

  if (this.cont.dataset.sspScaledMetadata) {
    try {
      this.contScaleMetadata = JSON.parse(this.cont.dataset.sspScaledMetadata);
    } catch (e) {
      // no-op
    }
    this.cont.removeAttribute('data-ssp-scaled-metadata');
  }

  this.contOriginalWidth = this.cont.offsetWidth;
  this.contOriginalHeight = this.cont.offsetHeight;
  this.contResizing = getElm(iframe, 'sssp-resizeCont');

  const cont = getElm(iframe, 'sssp-posCont');
  if (cont) {
    this.paw = cont.querySelector('.packa');
  }
  this.ad = ad;
  this.data = data;

  var d = {
    method: 'init',
    geom_info: this.getGeom(),
    dsp: this.ad.dsp,
  };

  var IS = getIS();
  var login = IS && IS.getIdentity('login');
  if (login && login.advert_uid) {
    d.said = login.advert_uid;
  }

  this.sendData(d);
};

sf.SF.count = 0; // celkovy pocet instanci sf
sf.SF.frames = {}; // pole instacni sf

sf.SF.prototype.sendData = function (data) {
  data.sfName = this.name; // tohle posleme vzdy kvuli identifikaci;
  if (this.iframe.contentWindow && this.iframe.contentWindow.postMessage) {
    this.iframe.contentWindow.postMessage(JSON.stringify(data), this.server);
  }
};

// nacte hodnoty o okne  a iframu
sf.SF.prototype.getGeom = function () {
  var ifrPos = this.iframe.getBoundingClientRect();
  var iv = this._getInView();
  var o = {
    // okno
    win: {
      t: window.screenY,
      b: window.innerHeight - window.screenY,
      l: window.screenX,
      r: window.innerWidth - window.screenY,
      w: window.innerWidth,
      h: window.innerHeight,
    },
    // iframe
    self: {
      t: ifrPos.top,
      b: ifrPos.bottom,
      l: ifrPos.left,
      r: ifrPos.right,
      w: ifrPos.width,
      h: ifrPos.height,
      z: window.getComputedStyle(this.iframe, null).getPropertyValue('z-index'),
      xiv: iv.x,
      yiv: iv.y,
    },
    exp: {
      t: 10000, // zde budou nuly nastavi stranka
      b: 10000, // kvuli nativnim inzarum
      l: 10000,
      r: 10000,
      xs: 0,
      ys: 0,
    },
  };
  return o;
};

sf.SF.prototype._register = function (data) {
  if (window.Cypress) {
    this.cont.dataset.cySfRegistered = '1';
  }
  const { width, height } = data.baseDimensions;

  if (this.ad.pawTimeoutId) {
    clearTimeout(this.ad.pawTimeoutId);
    sendPawVisibilityEvent('showPaw', { adContainer: this.cont });
  }

  this.contOriginalWidth = width;
  this.contOriginalHeight = height;
  this.contResizing.style.width = width + 'px';
  this.contResizing.style.height = height + 'px';

  const modifier = getScaleModifier(this.contResizing);

  this.cont.style.width = modifier * width + 'px';
  this.cont.style.height = modifier * height + 'px';
};

sf.SF.prototype._setResizeProperties = function (data) {
  const { width, height } = data;
  if (width) {
    this.contResizing.style.width = width + 'px';
  }

  if (height) {
    this.contResizing.style.height = height + 'px';
  }
};

sf.SF.prototype._getInView = function () {
  try {
    var rect = this.cont.getBoundingClientRect();
    if (!rect.width || !document.documentElement.clientWidth) {
      return { x: 0, y: 0 };
    }
  } catch (e) {
    return { x: 0, y: 0 };
  }

  // in case frame edges are equal, we compute real frame size from the ad size
  const right = rect.right === rect.left ? rect.left + this.ad.width : rect.right;
  const left = rect.left;
  const bottom = rect.bottom === rect.top ? rect.top + this.ad.height : rect.bottom;
  const top = rect.top;

  var w = right - left;
  var h = bottom - top;
  var W = document.documentElement.clientWidth;
  var H = document.documentElement.clientHeight;

  var vx = Math.max(0, Math.min(right, W) - Math.max(left, 0));
  var vy = Math.max(0, Math.min(bottom, H) - Math.max(top, 0));

  var px = vx / w;
  var py = vy / h;

  return { x: px, y: py };
};

function getZIndex() {
  return this.data.options && this.data.options.sfExpandZIndex !== undefined ? this.data.options.sfExpandZIndex : 1;
}

function styleAdContainerOnCollapse() {
  var cont = this.cont.style;
  var contResizing = this.contResizing.style;
  cont.width = this.contOriginalWidth + 'px';
  cont.height = this.contOriginalHeight + 'px';
  contResizing.position = 'static';
  contResizing.width = this.contScaleMetadata ? this.contScaleMetadata.wR + 'px' : '100%';
  contResizing.height = this.contScaleMetadata ? this.contScaleMetadata.hR + 'px' : '100%';
  contResizing.top = '';
  contResizing.right = '';
  contResizing.bottom = '';
  contResizing.left = '';
  contResizing.zIndex = '';
  if (this.paw) {
    this.paw.style.bottom = '0px';
    this.paw.style.right = '0px';
  }
}

function styleAdContainerOnExpand(resizeDimensions) {
  if (!conf.support['exp-push'] && !conf.support['exp-ovr']) {
    this.sendData({ method: 'expand', status: { error: 1, msg: 'not allowed' } });
    return;
  }

  const push = resizeDimensions.push && conf.support['exp-push'];
  this.contResizing.style.position = push ? 'static' : 'absolute';

  if (!push) {
    this.contResizing.style.zIndex = getZIndex.call(this);
  }

  const verticalDiff = this.contScaleMetadata ? this.contScaleMetadata.h - this.contScaleMetadata.hScaled : 0;
  const horizontalDiff = this.contScaleMetadata ? this.contScaleMetadata.w - this.contScaleMetadata.wScaled : 0;

  const countDirectionShift = function (value, oposite) {
    return (
      value +
      (~['t', 'b'].indexOf(oposite) ? verticalDiff : horizontalDiff) *
        (resizeDimensions[oposite] ? value / resizeDimensions[oposite] : 1)
    );
  };

  for (var o in resizeDimensions) {
    var value = resizeDimensions[o];

    switch (o) {
      case 't':
        this._setValues(countDirectionShift(value, 'b'), value, 'top', 'height', resizeDimensions);
        break;
      case 'b':
        this._setValues(countDirectionShift(value, 't'), value, 'bottom', 'height', resizeDimensions);
        break;
      case 'r':
        this._setValues(countDirectionShift(value, 'l'), value, 'right', 'width', resizeDimensions);
        break;
      case 'l':
        this._setValues(countDirectionShift(value, 'r'), value, 'left', 'width', resizeDimensions);
        break;
    }
  }
}

function togglePawTimeoutHandler(pawTimeout) {
  if (pawTimeout) {
    sendPawVisibilityEvent('hidePaw', { adContainer: this.cont });
    setTimeout(
      function () {
        sendPawVisibilityEvent('showPaw', { adContainer: this.cont });
      }.bind(this),
      pawTimeout
    );
  }
}

const callWithTimeout = function (fn, timeout) {
  if (timeout) {
    setTimeout(() => fn(), timeout);
  } else {
    fn();
  }
};

sf.SF.prototype._collapse = function (d) {
  togglePawTimeoutHandler.call(this, d.config.pawTimeout);

  callWithTimeout(
    function () {
      styleAdContainerOnCollapse.call(this);
      this.sendData({ method: 'collapse', status: { ok: true, msg: 'done' } });
    }.bind(this),
    d.config.resizeTimeout
  );
};

sf.SF.prototype._expand = function (d) {
  togglePawTimeoutHandler.call(this, d.config.pawTimeout);

  callWithTimeout(
    function () {
      styleAdContainerOnExpand.call(this, d.resizeDimensions);
      this.sendData({ method: 'expand', status: { ok: true, msg: 'done' } });
    }.bind(this),
    d.config.resizeTimeout
  );
};

sf.SF.prototype._setValues = function (
  directionValue,
  dimensionValue,
  direction,
  cssPropertyToChange,
  resizeDimensions
) {
  const resizingOffsetWidth = this.contResizing.offsetWidth;
  const resizingOffsetHeight = this.contResizing.offsetHeight;
  const offsetWidth = this.cont.offsetWidth;
  const offsetHeight = this.cont.offsetHeight;

  this.contResizing.style[direction] = -1 * directionValue + 'px';
  var push = resizeDimensions.push ? resizeDimensions.push : 0; // pokud nezada jedeme exp-ovr

  this.contResizing.style[cssPropertyToChange] =
    (~['top', 'bottom'].indexOf(direction) ? resizingOffsetHeight : resizingOffsetWidth) + dimensionValue + 'px';

  const dimensionDiff = dimensionValue * (this.contScaleMetadata ? this.contScaleMetadata.scale : 1);

  if (push) {
    this.cont.style[cssPropertyToChange] =
      (~['top', 'bottom'].indexOf(direction) ? offsetHeight : offsetWidth) + dimensionDiff + 'px';
  } else if (this.paw && (direction === 'bottom' || direction === 'right')) {
    // no paw postion adjustment needed for others
    this.paw.style[direction] = -1 * dimensionDiff + 'px';
    this.paw.style.zIndex = getZIndex.call(this);
  }
};

sf.SF.prototype._cookie = function (d) {
  var cookieName = d.cookieName;
  var cookieData = d.cookieData || null;
  var cook = '';

  if (cookieData) {
    if (!cookieData.info) {
      // data k ulozeni do cookie jsou povinna
      return;
    }
    cook = encodeURIComponent(cookieName) + '=' + encodeURIComponent(cookieData.info);
    // zadal i expire
    if (cookieData.expires) {
      var dE = new Date(cookieData.expires);
      var expires = '; expires=' + dE.toUTCString();
      cook += expires;
    }
    document.cookie = cook;
    this.sendData({ method: 'cookie', status: { ok: true, msg: 'done', cmd: 'write-cookie' } });
  } else {
    var newCookieName = encodeURIComponent(cookieName).replace(/[\-\.\+\*]/g, '\\$&');
    var c =
      decodeURIComponent(
        document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + newCookieName + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')
      ) || null;
    // FIXME zakazat cteni nasich cookie potrebuju list
    this.sendData({ method: 'cookie', status: { ok: true, msg: 'done', cmd: 'read-cookie' }, cookie: c });
  }
};

window.$sf = sf;
window.SF = sf.SF;
