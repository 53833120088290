import { createPawIconTemplate } from './createPawIconTemplate';
import { getPositions } from '../ads/adRepository';
import getJson from '../utils/helpers/getJson';
import { sendPawVisibilityEvent } from '../utils/events';
import { PAW_RENDER_DELAY } from '../../constants';

const writePaw = (container, dsp, pawOptions) => {
  if (pawOptions && pawOptions.pawDisable) {
    return;
  }

  const pawContainer = document.createElement('div');
  pawContainer.style = 'position:relative;';
  const innerAdContainer = container.querySelector('.sssp-resizeCont');
  pawContainer.innerHTML += createPawIconTemplate(dsp);
  innerAdContainer.appendChild(pawContainer);

  const pawRenderDelay = (pawOptions && pawOptions.pawTimeout) || PAW_RENDER_DELAY;

  // by default the paw is hidden, after pawRenderDelay timeout the paw is visible
  setTimeout(() => {
    sendPawVisibilityEvent('showPaw', { adContainer: container });
  }, pawRenderDelay);
};

const handlePawForIframe = ({ data }) => {
  const jsonData = getJson(data);
  if (!jsonData || jsonData.action !== 'add_paw') {
    return;
  }

  const positions = getPositions();
  const storedAd = positions.find((position) => {
    const { dsp, type, impressId } = position.ad;
    if (impressId && jsonData.impressId) {
      return impressId === jsonData.impressId;
    } else {
      // fallback before newer-style customEvent template is deployed
      return dsp === 'SKLIK' && type === 'iframe';
    }
  });
  const adContainer = storedAd && storedAd.containerElement;

  let pawOptions = null;
  if (storedAd.data && storedAd.data.options) {
    const { pawTimeout, pawDisable } = storedAd.data.options;
    pawOptions = {
      pawTimeout,
      pawDisable,
    };
  }

  // only add paw if not already present
  if (adContainer && adContainer.querySelector('div.packa') === null) {
    writePaw(adContainer, storedAd.ad.dsp, pawOptions);
  }
};

export const registerWritePawListenerForIframe = () => {
  window.addEventListener('message', handlePawForIframe);
};

const renderPawElementForCode = () => {
  let oldPositionsLength = 0;
  return () => {
    const positions = getPositions();
    const newPositions = positions.slice(oldPositionsLength, positions.length);
    oldPositionsLength = positions.length;

    newPositions.forEach((position) => {
      const adContainer = position?.containerElement;
      const { dsp, type } = position.ad;

      if (
        adContainer?.querySelector('div.packa') === null &&
        ['SKLIK', 'PUBMATIC', 'ADVERT'].includes(dsp) &&
        type === 'code'
      ) {
        const pawOptions =
          position?.data?.options?.pawTimeout || position?.data?.options?.pawDisable
            ? { ...position.data.options }
            : null;
        writePaw(adContainer, dsp, pawOptions);
      }
    });
  };
};

export const handlePawForCode = renderPawElementForCode();

export const registerWritePawListenerForCode = () => {
  window.addEventListener('requestpawelement', handlePawForCode);
};
