import { dbg } from './dbg';
import { getConfig } from '../config';
import callPositionErrorCallback from '../ads/callPositionErrorCallback';
import { emitDebugEvent } from '../utils/events';

/**
 * Zkontrolovani jedne pozice
 * @param positionOrig {Object} - definice pozice
 * @param opt {Object} - dodatecna konfigurace (AMPHTML, ...)
 */
const checkItem = function (positionOrig, opt) {
  // positionOrig == data

  const position = { ...positionOrig };

  const { params, optionsParams } = getConfig();
  dbg('info', '### check item ###');

  const elm = typeof position.id === 'string' ? document.getElementById(position.id) : position.id;

  if (!(opt && opt.AMPcallback) && !position.callback && !elm) {
    const errorMessage = `No callback or invalid ID passed to IM (zoneId: ${position.zoneId})`;
    // eslint-disable-next-line no-console
    console.log(`%c !!! ${errorMessage}`, 'font-size:20px; font-weight:bold; background:#ec0000; color: #fff');

    callPositionErrorCallback(position, errorMessage);
    emitDebugEvent('noCallbackOrId', { position });

    return null;
  }

  // neni element je callback, musi byt zadane rozmery v confu
  if (elm) {
    // kontrola zadanych rozmeru nesmi byt 0 ani undefined, alespon jeden musi byt definovan
    position.width = position.width || (elm.offsetWidth > 0 ? elm.offsetWidth : null);
    position.height = position.height || (elm.offsetHeight > 0 ? elm.offsetHeight : null);
    // vymazeme pokud neni zadano nebo ma element nulove rozmery
    if (position.width === null) {
      delete position.width;
    }

    if (position.height === null) {
      delete position.height;
    }
  }

  // set sspRendering flag every time
  const sspRendering = !position.callback;
  position.options = { ...(position.options || {}), ...{ sspRendering } };

  if (position.options && position.options.noSize) {
    dbg('noSize allowed in position:', position.zoneId);
  } else if (!position.width && !position.height) {
    const errorName = `No valid size of container or no size configuration passed to IM (${position.width}, ${position.height}).`;
    callPositionErrorCallback(position, errorName);
    emitDebugEvent('noValidSize', { position });
    return null;
  }

  dbg('callback and container check .. OK');

  // validate params
  params.forEach((paramName) => {
    if (position[paramName]) {
      const value = position[paramName];

      // validate options (only allowed options keys)
      if (value && paramName === 'options') {
        Object.keys(value).forEach((optionKey) => {
          if (!optionsParams.includes(optionKey)) {
            delete value[optionKey];
          }
        });
      }
    }
  });

  dbg(position.zoneId + ' position object prepared: ', position);
  return position;
};

export default checkItem;
