import { getConfig } from '../config';
import { documentWriteForceDisabled } from '../config/globalFlags';
import { dbg } from './dbg';
import { postscribe } from '@iva/postscribe';
import { replaceDocumentWrite, writeTo as dwWriteTo } from '@iva/documentwrite';

const documentwriteWhitelist = ['www.testpage.xyz'];

/// Use referer when location does not match parent location, this
/// can happen when the script is embedded from iframe.
/// @returns {string|null}
export const getLocation = () => {
  const location = window.location !== window.parent.location ? document.referrer : window.location.href;
  try {
    const url = new URL(location);
    return url.hostname;
  } catch (e) {
    return null;
  }
};

const reportError = (msg) => {
  const data = {
    msg,
    script: 'postscribe',
    referrer: document.referrer,
  };

  navigator.sendBeacon?.('https://confessor.iva.seznam.cz/postscribe', new URLSearchParams(data));
};

export const isDocumentWritePage = (list) => {
  const host = getLocation();
  const isWhitelisted = host !== null && list.includes(host);
  dbg(`Is it documentWrite page: ${isWhitelisted}`);
  return isWhitelisted;
};

export const writeTo = (dest, html) => {
  if (!document.writeTo) {
    dbg('Internal writeTo not available');
    return;
  }

  if (isDocumentWritePage(documentwriteWhitelist)) {
    dbg('Using internal writeTo');
    dwWriteTo(dest, html);
    return;
  }

  dbg('Using postscribe for writeTo');
  postscribe(dest, html, {
    autoFix: false,
    error: (e) => {
      const message = {
        msg: e.msg,
        html,
      };

      if (window._sssp?.ad) {
        message.sspMetadata = window._sssp.ad;
      }

      reportError(JSON.stringify(message));
    },
  });
};

export const setupDocumentWrite = () => {
  // Postscribe replaces docwrite internally,
  // there is no need to configure anything.
  // Remove this function when DW is not used.
  const { documentWriteOverride } = getConfig();
  const dwForceDisabled = documentWriteForceDisabled();

  if (dwForceDisabled || !documentWriteOverride || document.writeTo) {
    return;
  }

  if (isDocumentWritePage(documentwriteWhitelist)) {
    replaceDocumentWrite();
    const originalWrite = document.writeTo;
    document.writeTo = (...args) => {
      dbg('ad used writeTo');
      originalWrite(...args);
    };
    return;
  }

  document.writeTo = (dest, html) => {
    dbg('ad used postscribe');
    postscribe(dest, html, {
      autoFix: false,
      error: (e) => reportError(e.msg + '; postscribe was called from ad.'),
    });
  };
};
