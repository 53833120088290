import { getConfig } from '../config';
import { getCookie, setCookie, removeCookie } from '../utils/cookies';
import getJson from '../utils/helpers/getJson';
import { getCookieStorageDomainName } from '../utils/helpers/getDomainName';

const SSSP_COOKIE_NAME = 'sssp_session';

export const setSessionCookie = (sessionData) => {
  const { cookieExpiration, cookieDomain } = getConfig();
  const sessionValue = JSON.stringify(sessionData);
  setCookie({
    name: SSSP_COOKIE_NAME,
    value: encodeURIComponent(sessionValue),
    expires: [cookieExpiration, 'minutes'],
    domain: cookieDomain || getCookieStorageDomainName(window.location.hostname),
    sameSite: 'None',
    secure: true,
  });
};

/**
 * Odstrani session cookie pro HP feed
 */
export const removeSessionCookie = () => {
  const { cookieDomain } = getConfig();
  removeCookie({
    name: SSSP_COOKIE_NAME,
    domain: cookieDomain || getCookieStorageDomainName(window.location.hostname),
  });
};

export const getDataFromSessionCookie = () => {
  const cookieValue = getCookie(SSSP_COOKIE_NAME);
  const decodedCookie = decodeURIComponent(cookieValue);
  return getJson(decodedCookie);
};
